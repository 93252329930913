import React, { useEffect, useState } from "react";
import OtherSettingInput from "../../extra/OtherSettingInput";
import {
  getMonetization,
  appMonetizationSwitch,
} from "../../../store/slice/appSlice";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { baseURL } from "../../utils/config";

const OtherSetting = (props) => {
  const { monetizationData } = useSelector((state) => state.app);
  const location = useLocation();
  const [zSetting, setZSetting] = useState(false);

  useEffect(() => {
    if (baseURL === "https://testvirtualdesk.codderlab.com") {
      setZSetting(true);
    }
  }, []);

  const [e1, setE1] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e1Name",
    typeValue: "e1Value",
  });
  const [e2, setE2] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e2Name",
    typeValue: "e2Value",
  });
  const [e3, setE3] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e3Name",
    typeValue: "e3Value",
  });
  const [e4, setE4] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e4Name",
    typeValue: "e4Value",
  });
  const [e5, setE5] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e5Name",
    typeValue: "e5Value",
  });
  const [e6, setE6] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e6Name",
    typeValue: "e6Value",
  });
  const [e7, setE7] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e7Name",
    typeValue: "e7Value",
  });
  const [e8, setE8] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e8Name",
    typeValue: "e8Value",
  });
  const [e9, setE9] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e9Name",
    typeValue: "e9Value",
  });
  const [e10, setE10] = useState({
    text: "",
    title: "",
    typeAdd: "E",
    typeText: "e10Name",
    typeValue: "e10Value",
  });

  const [s1, setS1] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s1Name",
    typeValue: "s1Value",
  });
  const [s2, setS2] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s2Name",
    typeValue: "s2Value",
  });
  const [s3, setS3] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s3Name",
    typeValue: "s3Value",
  });
  const [s4, setS4] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s4Name",
    typeValue: "s4Value",
  });
  const [s5, setS5] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s5Name",
    typeValue: "s5Value",
  });
  const [s6, setS6] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s6Name",
    typeValue: "s6Value",
  });
  const [s7, setS7] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s7Name",
    typeValue: "s7Value",
  });
  const [s8, setS8] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s8Name",
    typeValue: "s8Value",
  });
  const [s9, setS9] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s9Name",
    typeValue: "s9Value",
  });
  const [s10, setS10] = useState({
    text: "",
    title: "",
    typeAdd: "S",
    typeText: "s10Name",
    typeValue: "s10Value",
  });

  const [z1, setZ1] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z1Name",
    typeValue: "z1Value",
  });
  const [z2, setZ2] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z2Name",
    typeValue: "z2Value",
  });
  const [z3, setZ3] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z3Name",
    typeValue: "z3Value",
  });
  const [z4, setZ4] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z4Name",
    typeValue: "z4Value",
  });
  const [z5, setZ5] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z5Name",
    typeValue: "z5Value",
  });
  const [z6, setZ6] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z6Name",
    typeValue: "z6Value",
  });
  const [z7, setZ7] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z7Name",
    typeValue: "z7Value",
  });
  const [z8, setZ8] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z8Name",
    typeValue: "z8Value",
  });
  const [z9, setZ9] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z9Name",
    typeValue: "z9Value",
  });
  const [z10, setZ10] = useState({
    text: "",
    title: "",
    typeAdd: "Z",
    typeText: "z10Name",
    typeValue: "z10Value",
  });

  const [b1, setB1] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b1Name",
    typeValue: "b1Value",
  });
  const [b2, setB2] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b2Name",
    typeValue: "b2Value",
  });
  const [b3, setB3] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b3Name",
    typeValue: "b3Value",
  });
  const [b4, setB4] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b4Name",
    typeValue: "b4Value",
  });
  const [b5, setB5] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b5Name",
    typeValue: "b5Value",
  });
  const [b6, setB6] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b6Name",
    typeValue: "b6Value",
  });
  const [b7, setB7] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b7Name",
    typeValue: "b7Value",
  });
  const [b8, setB8] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b8Name",
    typeValue: "b8Value",
  });
  const [b9, setB9] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b9Name",
    typeValue: "b9Value",
  });
  const [b10, setB10] = useState({
    text: "",
    title: "",
    typeAdd: "B",
    typeText: "b10Name",
    typeValue: "b10Value",
  });

  const [d1, setD1] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d1Name",
    typeValue: "d1Value",
  });
  const [d2, setD2] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d2Name",
    typeValue: "d2Value",
  });
  const [d3, setD3] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d3Name",
    typeValue: "d3Value",
  });
  const [d4, setD4] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d4Name",
    typeValue: "d4Value",
  });
  const [d5, setD5] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d5Name",
    typeValue: "d5Value",
  });
  const [d6, setD6] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d6Name",
    typeValue: "d6Value",
  });
  const [d7, setD7] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d7Name",
    typeValue: "d7Value",
  });
  const [d8, setD8] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d8Name",
    typeValue: "d8Value",
  });
  const [d9, setD9] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d9Name",
    typeValue: "d9Value",
  });
  const [d10, setD10] = useState({
    text: "",
    title: "",
    typeAdd: "D",
    typeText: "d10Name",
    typeValue: "d10Value",
  });

  const [k1, setK1] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k1Name",
    typeValue: "k1Value",
  });
  const [k2, setK2] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k2Name",
    typeValue: "k2Value",
  });
  const [k3, setK3] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k3Name",
    typeValue: "k3Value",
  });
  const [k4, setK4] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k4Name",
    typeValue: "k4Value",
  });
  const [k5, setK5] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k5Name",
    typeValue: "k5Value",
  });
  const [k6, setK6] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k6Name",
    typeValue: "k6Value",
  });
  const [k7, setK7] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k7Name",
    typeValue: "k7Value",
  });
  const [k8, setK8] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k8Name",
    typeValue: "k8Value",
  });
  const [k9, setK9] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k9Name",
    typeValue: "k9Value",
  });
  const [k10, setK10] = useState({
    text: "",
    title: "",
    typeAdd: "K",
    typeText: "k10Name",
    typeValue: "k10Value",
  });

  const [e1Switch, setE1Switch] = useState(false);
  const [e2Switch, setE2Switch] = useState(false);
  const [e3Switch, setE3Switch] = useState(false);
  const [e4Switch, setE4Switch] = useState(false);
  const [e5Switch, setE5Switch] = useState(false);
  const [e6Switch, setE6Switch] = useState(false);
  const [e7Switch, setE7Switch] = useState(false);
  const [e8Switch, setE8Switch] = useState(false);
  const [e9Switch, setE9Switch] = useState(false);
  const [e10Switch, setE10Switch] = useState(false);

  const [s1Switch, setS1Switch] = useState(false);
  const [s2Switch, setS2Switch] = useState(false);
  const [s3Switch, setS3Switch] = useState(false);
  const [s4Switch, setS4Switch] = useState(false);
  const [s5Switch, setS5Switch] = useState(false);
  const [s6Switch, setS6Switch] = useState(false);
  const [s7Switch, setS7Switch] = useState(false);
  const [s8Switch, setS8Switch] = useState(false);
  const [s9Switch, setS9Switch] = useState(false);
  const [s10Switch, setS10Switch] = useState(false);

  const [z1Switch, setZ1Switch] = useState(false);
  const [z2Switch, setZ2Switch] = useState(false);
  const [z3Switch, setZ3Switch] = useState(false);
  const [z4Switch, setZ4Switch] = useState(false);
  const [z5Switch, setZ5Switch] = useState(false);
  const [z6Switch, setZ6Switch] = useState(false);
  const [z7Switch, setZ7Switch] = useState(false);
  const [z8Switch, setZ8Switch] = useState(false);
  const [z9Switch, setZ9Switch] = useState(false);
  const [z10Switch, setZ10Switch] = useState(false);

  const [b1Switch, setB1Switch] = useState(false);
  const [b2Switch, setB2Switch] = useState(false);
  const [b3Switch, setB3Switch] = useState(false);
  const [b4Switch, setB4Switch] = useState(false);
  const [b5Switch, setB5Switch] = useState(false);
  const [b6Switch, setB6Switch] = useState(false);
  const [b7Switch, setB7Switch] = useState(false);
  const [b8Switch, setB8Switch] = useState(false);
  const [b9Switch, setB9Switch] = useState(false);
  const [b10Switch, setB10Switch] = useState(false);

  const [d1Switch, setD1Switch] = useState(false);
  const [d2Switch, setD2Switch] = useState(false);
  const [d3Switch, setD3Switch] = useState(false);
  const [d4Switch, setD4Switch] = useState(false);
  const [d5Switch, setD5Switch] = useState(false);
  const [d6Switch, setD6Switch] = useState(false);
  const [d7Switch, setD7Switch] = useState(false);
  const [d8Switch, setD8Switch] = useState(false);
  const [d9Switch, setD9Switch] = useState(false);
  const [d10Switch, setD10Switch] = useState(false);

  const [k1Switch, setK1Switch] = useState(false);
  const [k2Switch, setK2Switch] = useState(false);
  const [k3Switch, setK3Switch] = useState(false);
  const [k4Switch, setK4Switch] = useState(false);
  const [k5Switch, setK5Switch] = useState(false);
  const [k6Switch, setK6Switch] = useState(false);
  const [k7Switch, setK7Switch] = useState(false);
  const [k8Switch, setK8Switch] = useState(false);
  const [k9Switch, setK9Switch] = useState(false);
  const [k10Switch, setK10Switch] = useState(false);

  const handleSwitchChange = (type, value) => {
    const payload = {
      appId: location.state.editAppData?._id,
      type: type,
      value: value,
    };
    props.appMonetizationSwitch(payload);
  };

  useEffect(() => {
    const payload = location.state.editAppData?._id;
    props.getMonetization(payload);
  }, [location.state]);

  useEffect(() => {
    setE1({
      ...e1,
      text: monetizationData?.E1?.e1Name,
      title: monetizationData?.E1?.e1Value,
    });
    setE2({
      ...e2,
      text: monetizationData?.E2?.e2Name,
      title: monetizationData?.E2?.e2Value,
    });
    setE3({
      ...e3,
      text: monetizationData?.E3?.e3Name,
      title: monetizationData?.E3?.e3Value,
    });
    setE4({
      ...e4,
      text: monetizationData?.E4?.e4Name,
      title: monetizationData?.E4?.e4Value,
    });
    setE5({
      ...e5,
      text: monetizationData?.E5?.e5Name,
      title: monetizationData?.E5?.e5Value,
    });
    setE6({
      ...e6,
      text: monetizationData?.E6?.e6Name,
      title: monetizationData?.E6?.e6Value,
    });
    setE7({
      ...e7,
      text: monetizationData?.E7?.e7Name,
      title: monetizationData?.E7?.e7Value,
    });
    setE8({
      ...e8,
      text: monetizationData?.E8?.e8Name,
      title: monetizationData?.E8?.e8Value,
    });
    setE9({
      ...e9,
      text: monetizationData?.E9?.e9Name,
      title: monetizationData?.E9?.e9Value,
    });
    setE10({
      ...e10,
      text: monetizationData?.E10?.e10Name,
      title: monetizationData?.E10?.e10Value,
    });

    setE1Switch(monetizationData?.E1?.e1Switch);
    setE2Switch(monetizationData?.E2?.e2Switch);
    setE3Switch(monetizationData?.E3?.e3Switch);
    setE4Switch(monetizationData?.E4?.e4Switch);
    setE5Switch(monetizationData?.E5?.e5Switch);
    setE6Switch(monetizationData?.E6?.e6Switch);
    setE7Switch(monetizationData?.E7?.e7Switch);
    setE8Switch(monetizationData?.E8?.e8Switch);
    setE9Switch(monetizationData?.E9?.e9Switch);
    setE10Switch(monetizationData?.E10?.e10Switch);

    setS1({
      ...s1,
      text: monetizationData?.S1?.s1Name,
      title: monetizationData?.S1?.s1Value,
    });
    setS2({
      ...s2,
      text: monetizationData?.S2?.s2Name,
      title: monetizationData?.S2?.s2Value,
    });
    setS3({
      ...s3,
      text: monetizationData?.S3?.s3Name,
      title: monetizationData?.S3?.s3Value,
    });
    setS4({
      ...s4,
      text: monetizationData?.S4?.s4Name,
      title: monetizationData?.S4?.s4Value,
    });
    setS5({
      ...s5,
      text: monetizationData?.S5?.s5Name,
      title: monetizationData?.S5?.s5Value,
    });
    setS6({
      ...s6,
      text: monetizationData?.S6?.s6Name,
      title: monetizationData?.S6?.s6Value,
    });
    setS7({
      ...s7,
      text: monetizationData?.S7?.s7Name,
      title: monetizationData?.S7?.s7Value,
    });
    setS8({
      ...s8,
      text: monetizationData?.S8?.s8Name,
      title: monetizationData?.S8?.s8Value,
    });
    setS9({
      ...s9,
      text: monetizationData?.S9?.s9Name,
      title: monetizationData?.S9?.s9Value,
    });
    setS10({
      ...s10,
      text: monetizationData?.S10?.s10Name,
      title: monetizationData?.S10?.s10Value,
    });

    setS1Switch(monetizationData?.S1?.s1Switch);
    setS2Switch(monetizationData?.S2?.s2Switch);
    setS3Switch(monetizationData?.S3?.s3Switch);
    setS4Switch(monetizationData?.S4?.s4Switch);
    setS5Switch(monetizationData?.S5?.s5Switch);
    setS6Switch(monetizationData?.S6?.s6Switch);
    setS7Switch(monetizationData?.S7?.s7Switch);
    setS8Switch(monetizationData?.S8?.s8Switch);
    setS9Switch(monetizationData?.S9?.s9Switch);
    setS10Switch(monetizationData?.S10?.s10Switch);

    setZ1({
      ...z1,
      text: monetizationData?.Z1?.z1Name,
      title: monetizationData?.Z1?.z1Value,
    });
    setZ2({
      ...z2,
      text: monetizationData?.Z2?.z2Name,
      title: monetizationData?.Z2?.z2Value,
    });
    setZ3({
      ...z3,
      text: monetizationData?.Z3?.z3Name,
      title: monetizationData?.Z3?.z3Value,
    });
    setZ4({
      ...z4,
      text: monetizationData?.Z4?.z4Name,
      title: monetizationData?.Z4?.z4Value,
    });
    setZ5({
      ...z5,
      text: monetizationData?.Z5?.z5Name,
      title: monetizationData?.Z5?.z5Value,
    });
    setZ6({
      ...z6,
      text: monetizationData?.Z6?.z6Name,
      title: monetizationData?.Z6?.z6Value,
    });
    setZ7({
      ...z7,
      text: monetizationData?.Z7?.z7Name,
      title: monetizationData?.Z7?.z7Value,
    });
    setZ8({
      ...z8,
      text: monetizationData?.Z8?.z8Name,
      title: monetizationData?.Z8?.z8Value,
    });
    setZ9({
      ...z9,
      text: monetizationData?.Z9?.z9Name,
      title: monetizationData?.Z9?.z9Value,
    });
    setZ10({
      ...z10,
      text: monetizationData?.Z10?.z10Name,
      title: monetizationData?.Z10?.z10Value,
    });

    setZ1Switch(monetizationData?.Z1?.z1Switch);
    setZ2Switch(monetizationData?.Z2?.z2Switch);
    setZ3Switch(monetizationData?.Z3?.z3Switch);
    setZ4Switch(monetizationData?.Z4?.z4Switch);
    setZ5Switch(monetizationData?.Z5?.z5Switch);
    setZ6Switch(monetizationData?.Z6?.z6Switch);
    setZ7Switch(monetizationData?.Z7?.z7Switch);
    setZ8Switch(monetizationData?.Z8?.z8Switch);
    setZ9Switch(monetizationData?.Z9?.z9Switch);
    setZ10Switch(monetizationData?.Z10?.z10Switch);

    setB1({
      ...b1,
      text: monetizationData?.B1?.b1Name,
      title: monetizationData?.B1?.b1Value,
    });
    setB2({
      ...b2,
      text: monetizationData?.B2?.b2Name,
      title: monetizationData?.B2?.b2Value,
    });
    setB3({
      ...b3,
      text: monetizationData?.B3?.b3Name,
      title: monetizationData?.B3?.b3Value,
    });
    setB4({
      ...b4,
      text: monetizationData?.B4?.b4Name,
      title: monetizationData?.B4?.b4Value,
    });
    setB5({
      ...b5,
      text: monetizationData?.B5?.b5Name,
      title: monetizationData?.B5?.b5Value,
    });
    setB6({
      ...b6,
      text: monetizationData?.B6?.b6Name,
      title: monetizationData?.B6?.b6Value,
    });
    setB7({
      ...b7,
      text: monetizationData?.B7?.b7Name,
      title: monetizationData?.B7?.b7Value,
    });
    setB8({
      ...b8,
      text: monetizationData?.B8?.b8Name,
      title: monetizationData?.B8?.b8Value,
    });
    setB9({
      ...b9,
      text: monetizationData?.B9?.b9Name,
      title: monetizationData?.B9?.b9Value,
    });
    setB10({
      ...b10,
      text: monetizationData?.B10?.b10Name,
      title: monetizationData?.B10?.b10Value,
    });

    setB1Switch(monetizationData?.B1?.b1Switch);
    setB2Switch(monetizationData?.B2?.b2Switch);
    setB3Switch(monetizationData?.B3?.b3Switch);
    setB4Switch(monetizationData?.B4?.b4Switch);
    setB5Switch(monetizationData?.B5?.b5Switch);
    setB6Switch(monetizationData?.B6?.b6Switch);
    setB7Switch(monetizationData?.B7?.b7Switch);
    setB8Switch(monetizationData?.B8?.b8Switch);
    setB9Switch(monetizationData?.B9?.b9Switch);
    setB10Switch(monetizationData?.B10?.b10Switch);

    // For d
    setD1({
      ...d1,
      text: monetizationData?.D1?.d1Name,
      title: monetizationData?.D1?.d1Value,
    });
    setD2({
      ...d2,
      text: monetizationData?.D2?.d2Name,
      title: monetizationData?.D2?.d2Value,
    });
    setD3({
      ...d3,
      text: monetizationData?.D3?.d3Name,
      title: monetizationData?.D3?.d3Value,
    });
    setD4({
      ...d4,
      text: monetizationData?.D4?.d4Name,
      title: monetizationData?.D4?.d4Value,
    });
    setD5({
      ...d5,
      text: monetizationData?.D5?.d5Name,
      title: monetizationData?.D5?.d5Value,
    });
    setD6({
      ...d6,
      text: monetizationData?.D6?.d6Name,
      title: monetizationData?.D6?.d6Value,
    });
    setD7({
      ...d7,
      text: monetizationData?.D7?.d7Name,
      title: monetizationData?.D7?.d7Value,
    });
    setD8({
      ...d8,
      text: monetizationData?.D8?.d8Name,
      title: monetizationData?.D8?.d8Value,
    });
    setD9({
      ...d9,
      text: monetizationData?.D9?.d9Name,
      title: monetizationData?.D9?.d9Value,
    });
    setD10({
      ...d10,
      text: monetizationData?.D10?.d10Name,
      title: monetizationData?.D10?.d10Value,
    });

    setD1Switch(monetizationData?.D1?.d1Switch);
    setD2Switch(monetizationData?.D2?.d2Switch);
    setD3Switch(monetizationData?.D3?.d3Switch);
    setD4Switch(monetizationData?.D4?.d4Switch);
    setD5Switch(monetizationData?.D5?.d5Switch);
    setD6Switch(monetizationData?.D6?.d6Switch);
    setD7Switch(monetizationData?.D7?.d7Switch);
    setD8Switch(monetizationData?.D8?.d8Switch);
    setD9Switch(monetizationData?.D9?.d9Switch);
    setD10Switch(monetizationData?.D10?.d10Switch);

    // For k
    setK1({
      ...k1,
      text: monetizationData?.K1?.k1Name,
      title: monetizationData?.K1?.k1Value,
    });
    setK2({
      ...k2,
      text: monetizationData?.K2?.k2Name,
      title: monetizationData?.K2?.k2Value,
    });
    setK3({
      ...k3,
      text: monetizationData?.K3?.k3Name,
      title: monetizationData?.K3?.k3Value,
    });
    setK4({
      ...k4,
      text: monetizationData?.K4?.k4Name,
      title: monetizationData?.K4?.k4Value,
    });
    setK5({
      ...k5,
      text: monetizationData?.K5?.k5Name,
      title: monetizationData?.K5?.k5Value,
    });
    setK6({
      ...k6,
      text: monetizationData?.K6?.k6Name,
      title: monetizationData?.K6?.k6Value,
    });
    setK7({
      ...k7,
      text: monetizationData?.K7?.k7Name,
      title: monetizationData?.K7?.k7Value,
    });
    setK8({
      ...k8,
      text: monetizationData?.K8?.k8Name,
      title: monetizationData?.K8?.k8Value,
    });
    setK9({
      ...k9,
      text: monetizationData?.K9?.k9Name,
      title: monetizationData?.K9?.k9Value,
    });
    setK10({
      ...k10,
      text: monetizationData?.K10?.k10Name,
      title: monetizationData?.K10?.k10Value,
    });

    setK1Switch(monetizationData?.K1?.k1Switch);
    setK2Switch(monetizationData?.K2?.k2Switch);
    setK3Switch(monetizationData?.K3?.k3Switch);
    setK4Switch(monetizationData?.K4?.k4Switch);
    setK5Switch(monetizationData?.K5?.k5Switch);
    setK6Switch(monetizationData?.K6?.k6Switch);
    setK7Switch(monetizationData?.K7?.k7Switch);
    setK8Switch(monetizationData?.K8?.k8Switch);
    setK9Switch(monetizationData?.K9?.k9Switch);
    setK10Switch(monetizationData?.K10?.k10Switch);
  }, [monetizationData]);

  return (
    <div className="other-setting">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="e1Box">
            <div className="input-setting">
              <OtherSettingInput
                label={"E1"}
                labelValue={"E1 Value"}
                valueData={e1}
                onClickSwitch={() => handleSwitchChange("e1Switch", e1Switch)}
                switchValue={e1Switch}
                onChangeSwitch={setE1Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E2"}
                labelValue={"E2 Value"}
                valueData={e2}
                onClickSwitch={() => handleSwitchChange("e2Switch", e2Switch)}
                switchValue={e2Switch}
                onChangeSwitch={setE2Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E3"}
                labelValue={"E3 Value"}
                valueData={e3}
                onClickSwitch={() => handleSwitchChange("e3Switch", e3Switch)}
                switchValue={e3Switch}
                onChangeSwitch={setE3Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E4"}
                labelValue={"E4 Value"}
                valueData={e4}
                onClickSwitch={() => handleSwitchChange("e4Switch", e4Switch)}
                switchValue={e4Switch}
                onChangeSwitch={setE4Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E5"}
                labelValue={"E5 Value"}
                valueData={e5}
                onClickSwitch={() => handleSwitchChange("e5Switch", e5Switch)}
                switchValue={e5Switch}
                onChangeSwitch={setE5Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E6"}
                labelValue={"E6 Value"}
                valueData={e6}
                onClickSwitch={() => handleSwitchChange("e6Switch", e6Switch)}
                switchValue={e6Switch}
                onChangeSwitch={setE6Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E7"}
                labelValue={"E7 Value"}
                valueData={e7}
                onClickSwitch={() => handleSwitchChange("e7Switch", e7Switch)}
                switchValue={e7Switch}
                onChangeSwitch={setE7Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E8"}
                labelValue={"E8 Value"}
                valueData={e8}
                onClickSwitch={() => handleSwitchChange("e8Switch", e8Switch)}
                switchValue={e8Switch}
                onChangeSwitch={setE8Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E9"}
                labelValue={"E9 Value"}
                valueData={e9}
                onClickSwitch={() => handleSwitchChange("e9Switch", e9Switch)}
                switchValue={e9Switch}
                onChangeSwitch={setE9Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"E10"}
                labelValue={"E10 Value"}
                valueData={e10}
                onClickSwitch={() => handleSwitchChange("e10Switch", e10Switch)}
                switchValue={e10Switch}
                onChangeSwitch={setE10Switch}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="e1Box">
            <div className="input-setting ">
              <OtherSettingInput
                label={"S1"}
                labelValue={"S1 Value"}
                valueData={s1}
                onClickSwitch={() => handleSwitchChange("s1Switch", s1Switch)}
                switchValue={s1Switch}
                onChangeSwitch={setS1Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S2"}
                labelValue={"S2 Value"}
                valueData={s2}
                onClickSwitch={() => handleSwitchChange("s2Switch", s2Switch)}
                switchValue={s2Switch}
                onChangeSwitch={setS2Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S3"}
                labelValue={"S3 Value"}
                valueData={s3}
                onClickSwitch={() => handleSwitchChange("s3Switch", s3Switch)}
                switchValue={s3Switch}
                onChangeSwitch={setS3Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S4"}
                labelValue={"S4 Value"}
                valueData={s4}
                onClickSwitch={() => handleSwitchChange("s4Switch", s4Switch)}
                switchValue={s4Switch}
                onChangeSwitch={setS4Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S5"}
                labelValue={"S5 Value"}
                valueData={s5}
                onClickSwitch={() => handleSwitchChange("s5Switch", s5Switch)}
                switchValue={s5Switch}
                onChangeSwitch={setS5Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S6"}
                labelValue={"S6 Value"}
                valueData={s6}
                onClickSwitch={() => handleSwitchChange("s6Switch", s6Switch)}
                switchValue={s6Switch}
                onChangeSwitch={setS6Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S7"}
                labelValue={"S7 Value"}
                valueData={s7}
                onClickSwitch={() => handleSwitchChange("s7Switch", s7Switch)}
                switchValue={s7Switch}
                onChangeSwitch={setS7Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S8"}
                labelValue={"S8 Value"}
                valueData={s8}
                onClickSwitch={() => handleSwitchChange("s8Switch", s8Switch)}
                switchValue={s8Switch}
                onChangeSwitch={setS8Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S9"}
                labelValue={"S9 Value"}
                valueData={s9}
                onClickSwitch={() => handleSwitchChange("s9Switch", s9Switch)}
                switchValue={s9Switch}
                onChangeSwitch={setS9Switch}
              />
            </div>
            <div className="input-setting mt-3">
              <OtherSettingInput
                label={"S10"}
                labelValue={"S10 Value"}
                valueData={s10}
                onClickSwitch={() => handleSwitchChange("s10Switch", s10Switch)}
                switchValue={s10Switch}
                onChangeSwitch={setS10Switch}
              />
            </div>
          </div>
        </div>
 
          <>
            <div className="col-12 col-md-6 mt-2">
              <div className="e1Box">
                <div className="input-setting ">
                  <OtherSettingInput
                    label={"Z1"}
                    labelValue={"Z1 Value"}
                    valueData={z1}
                    onClickSwitch={() =>
                      handleSwitchChange("z1Switch", z1Switch)
                    }
                    switchValue={z1Switch}
                    onChangeSwitch={setZ1Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z2"}
                    labelValue={"Z2 Value"}
                    valueData={z2}
                    onClickSwitch={() =>
                      handleSwitchChange("z2Switch", z2Switch)
                    }
                    switchValue={z2Switch}
                    onChangeSwitch={setZ2Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z3"}
                    labelValue={"Z3 Value"}
                    valueData={z3}
                    onClickSwitch={() =>
                      handleSwitchChange("z3Switch", z3Switch)
                    }
                    switchValue={z3Switch}
                    onChangeSwitch={setZ3Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z4"}
                    labelValue={"Z4 Value"}
                    valueData={z4}
                    onClickSwitch={() =>
                      handleSwitchChange("z4Switch", z4Switch)
                    }
                    switchValue={z4Switch}
                    onChangeSwitch={setZ4Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z5"}
                    labelValue={"Z5 Value"}
                    valueData={z5}
                    onClickSwitch={() =>
                      handleSwitchChange("z5Switch", z5Switch)
                    }
                    switchValue={z5Switch}
                    onChangeSwitch={setZ5Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z6"}
                    labelValue={"Z6 Value"}
                    valueData={z6}
                    onClickSwitch={() =>
                      handleSwitchChange("z6Switch", z6Switch)
                    }
                    switchValue={z6Switch}
                    onChangeSwitch={setZ6Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z7"}
                    labelValue={"Z7 Value"}
                    valueData={z7}
                    onClickSwitch={() =>
                      handleSwitchChange("z7Switch", z7Switch)
                    }
                    switchValue={z7Switch}
                    onChangeSwitch={setZ7Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z8"}
                    labelValue={"Z8 Value"}
                    valueData={z8}
                    onClickSwitch={() =>
                      handleSwitchChange("z8Switch", z8Switch)
                    }
                    switchValue={z8Switch}
                    onChangeSwitch={setZ8Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z9"}
                    labelValue={"Z9 Value"}
                    valueData={z9}
                    onClickSwitch={() =>
                      handleSwitchChange("z9Switch", z9Switch)
                    }
                    switchValue={z9Switch}
                    onChangeSwitch={setZ9Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"Z10"}
                    labelValue={"Z10 Value"}
                    valueData={z10}
                    onClickSwitch={() =>
                      handleSwitchChange("z10Switch", z10Switch)
                    }
                    switchValue={z10Switch}
                    onChangeSwitch={setZ10Switch}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 mt-2">
              <div className="e1Box">
                <div className="input-setting ">
                  <OtherSettingInput
                    label={"B1"}
                    labelValue={"B1 Value"}
                    valueData={b1}
                    onClickSwitch={() =>
                      handleSwitchChange("b1Switch", b1Switch)
                    }
                    switchValue={b1Switch}
                    onChangeSwitch={setB1Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B2"}
                    labelValue={"B2 Value"}
                    valueData={b2}
                    onClickSwitch={() =>
                      handleSwitchChange("b2Switch", b2Switch)
                    }
                    switchValue={b2Switch}
                    onChangeSwitch={setB2Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B3"}
                    labelValue={"B3 Value"}
                    valueData={b3}
                    onClickSwitch={() =>
                      handleSwitchChange("b3Switch", b3Switch)
                    }
                    switchValue={b3Switch}
                    onChangeSwitch={setB3Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B4"}
                    labelValue={"B4 Value"}
                    valueData={b4}
                    onClickSwitch={() =>
                      handleSwitchChange("b4Switch", b4Switch)
                    }
                    switchValue={b4Switch}
                    onChangeSwitch={setB4Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B5"}
                    labelValue={"B5 Value"}
                    valueData={b5}
                    onClickSwitch={() =>
                      handleSwitchChange("b5Switch", b5Switch)
                    }
                    switchValue={b5Switch}
                    onChangeSwitch={setB5Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B6"}
                    labelValue={"B6 Value"}
                    valueData={b6}
                    onClickSwitch={() =>
                      handleSwitchChange("b6Switch", b6Switch)
                    }
                    switchValue={b6Switch}
                    onChangeSwitch={setB6Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B7"}
                    labelValue={"B7 Value"}
                    valueData={b7}
                    onClickSwitch={() =>
                      handleSwitchChange("b7Switch", b7Switch)
                    }
                    switchValue={b7Switch}
                    onChangeSwitch={setB7Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B8"}
                    labelValue={"B8 Value"}
                    valueData={b8}
                    onClickSwitch={() =>
                      handleSwitchChange("b8Switch", b8Switch)
                    }
                    switchValue={b8Switch}
                    onChangeSwitch={setB8Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B9"}
                    labelValue={"B9 Value"}
                    valueData={b9}
                    onClickSwitch={() =>
                      handleSwitchChange("b9Switch", b9Switch)
                    }
                    switchValue={b9Switch}
                    onChangeSwitch={setB9Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"B10"}
                    labelValue={"B10 Value"}
                    valueData={b10}
                    onClickSwitch={() =>
                      handleSwitchChange("b10Switch", b10Switch)
                    }
                    switchValue={b10Switch}
                    onChangeSwitch={setB10Switch}
                  />
                </div>
              </div>
            </div>

          </>
      
            <div className="col-12 col-md-6 mt-2">
              <div className="e1Box">
                <div className="input-setting">
                  <OtherSettingInput
                    label={"D1"}
                    labelValue={"D1 Value"}
                    valueData={d1}
                    onClickSwitch={() =>
                      handleSwitchChange("d1Switch", d1Switch)
                    }
                    switchValue={d1Switch}
                    onChangeSwitch={setD1Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D2"}
                    labelValue={"D2 Value"}
                    valueData={d2}
                    onClickSwitch={() =>
                      handleSwitchChange("d2Switch", d2Switch)
                    }
                    switchValue={d2Switch}
                    onChangeSwitch={setD2Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D3"}
                    labelValue={"D3 Value"}
                    valueData={d3}
                    onClickSwitch={() =>
                      handleSwitchChange("d3Switch", d3Switch)
                    }
                    switchValue={d3Switch}
                    onChangeSwitch={setD3Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D4"}
                    labelValue={"D4 Value"}
                    valueData={d4}
                    onClickSwitch={() =>
                      handleSwitchChange("d4Switch", d4Switch)
                    }
                    switchValue={d4Switch}
                    onChangeSwitch={setD4Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D5"}
                    labelValue={"D5 Value"}
                    valueData={d5}
                    onClickSwitch={() =>
                      handleSwitchChange("d5Switch", d5Switch)
                    }
                    switchValue={d5Switch}
                    onChangeSwitch={setD5Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D6"}
                    labelValue={"D6 Value"}
                    valueData={d6}
                    onClickSwitch={() =>
                      handleSwitchChange("d6Switch", d6Switch)
                    }
                    switchValue={d6Switch}
                    onChangeSwitch={setD6Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D7"}
                    labelValue={"D7 Value"}
                    valueData={d7}
                    onClickSwitch={() =>
                      handleSwitchChange("d7Switch", d7Switch)
                    }
                    switchValue={d7Switch}
                    onChangeSwitch={setD7Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D8"}
                    labelValue={"D8 Value"}
                    valueData={d8}
                    onClickSwitch={() =>
                      handleSwitchChange("d8Switch", d8Switch)
                    }
                    switchValue={d8Switch}
                    onChangeSwitch={setD8Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D9"}
                    labelValue={"D9 Value"}
                    valueData={d9}
                    onClickSwitch={() =>
                      handleSwitchChange("d9Switch", d9Switch)
                    }
                    switchValue={d9Switch}
                    onChangeSwitch={setD9Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"D10"}
                    labelValue={"D10 Value"}
                    valueData={d10}
                    onClickSwitch={() =>
                      handleSwitchChange("d10Switch", d10Switch)
                    }
                    switchValue={d10Switch}
                    onChangeSwitch={setD10Switch}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 mt-2">
              <div className="e1Box">
                <div className="input-setting">
                  <OtherSettingInput
                    label={"K1"}
                    labelValue={"K1 Value"}
                    valueData={k1}
                    onClickSwitch={() =>
                      handleSwitchChange("k1Switch", k1Switch)
                    }
                    switchValue={k1Switch}
                    onChangeSwitch={setK1Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K2"}
                    labelValue={"K2 Value"}
                    valueData={k2}
                    onClickSwitch={() =>
                      handleSwitchChange("k2Switch", k2Switch)
                    }
                    switchValue={k2Switch}
                    onChangeSwitch={setK2Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K3"}
                    labelValue={"K3 Value"}
                    valueData={k3}
                    onClickSwitch={() =>
                      handleSwitchChange("k3Switch", k3Switch)
                    }
                    switchValue={k3Switch}
                    onChangeSwitch={setK3Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K4"}
                    labelValue={"K4 Value"}
                    valueData={k4}
                    onClickSwitch={() =>
                      handleSwitchChange("k4Switch", k4Switch)
                    }
                    switchValue={k4Switch}
                    onChangeSwitch={setK4Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K5"}
                    labelValue={"K5 Value"}
                    valueData={k5}
                    onClickSwitch={() =>
                      handleSwitchChange("k5Switch", k5Switch)
                    }
                    switchValue={k5Switch}
                    onChangeSwitch={setK5Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K6"}
                    labelValue={"K6 Value"}
                    valueData={k6}
                    onClickSwitch={() =>
                      handleSwitchChange("k6Switch", k6Switch)
                    }
                    switchValue={k6Switch}
                    onChangeSwitch={setK6Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K7"}
                    labelValue={"K7 Value"}
                    valueData={k7}
                    onClickSwitch={() =>
                      handleSwitchChange("k7Switch", k7Switch)
                    }
                    switchValue={k7Switch}
                    onChangeSwitch={setK7Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K8"}
                    labelValue={"K8 Value"}
                    valueData={k8}
                    onClickSwitch={() =>
                      handleSwitchChange("k8Switch", k8Switch)
                    }
                    switchValue={k8Switch}
                    onChangeSwitch={setK8Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K9"}
                    labelValue={"K9 Value"}
                    valueData={k9}
                    onClickSwitch={() =>
                      handleSwitchChange("k9Switch", k9Switch)
                    }
                    switchValue={k9Switch}
                    onChangeSwitch={setK9Switch}
                  />
                </div>
                <div className="input-setting mt-3">
                  <OtherSettingInput
                    label={"K10"}
                    labelValue={"K10 Value"}
                    valueData={k10}
                    onClickSwitch={() =>
                      handleSwitchChange("k10Switch", k10Switch)
                    }
                    switchValue={k10Switch}
                    onChangeSwitch={setK10Switch}
                  />
                </div>
              </div>
            </div>
      </div>
    </div>
  );
};
export default connect(null, { getMonetization, appMonetizationSwitch })(
  OtherSetting
);
