import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";


const initialState = {
    withdraw: [],
    isLoading: false,
    isSkeleton: false,
    total: null
}


export const getWithdrawRequest = createAsyncThunk("admin/withdrawRequest/fetchRequests", async (payload) => {

    return apiInstanceFetch.get(`admin/withdrawRequest/fetchRequests?type=${payload?.type}&start=${payload?.start}&limit=${payload?.limit}`)
})

export const acceptRequest = createAsyncThunk("admin/withdrawRequest/acceptWithdrawalRequest", async (payload) => {

    return apiInstanceFetch.patch(`admin/withdrawRequest/acceptWithdrawalRequest`)
})

export const rejectRequest = createAsyncThunk("admin/withdrawRequest/declineWithdrawalRequest", async (payload) => {

    return apiInstanceFetch.patch(`admin/withdrawRequest/declineWithdrawalRequest`)
})



const withdrawSlice = createSlice({
    name: "withdrawSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getWithdrawRequest.pending, (state, action) => {
            state.isSkeleton = true;
        })

        builder.addCase(getWithdrawRequest.fulfilled, (state, action) => {
            state.isSkeleton = false;
            state.withdraw = action?.payload?.data
            state.total = action?.payload?.total
        })


        builder.addCase(getWithdrawRequest.rejected, (state, action) => {
            state.isSkeleton = false;
        })


    }
})
export default withdrawSlice.reducer;