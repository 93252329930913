import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { appUserGet, hostImageVerifySwitch } from "../../../store/slice/appSlice";
import { getWithdrawRequest } from "../../../store/slice/withdrawRequestSlice";
import DateRange from "../../extra/DateRangePicker";
import { useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../extra/Table";
import { baseURL } from "../../utils/config";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import NoImage from "../../../assets/images/noImage.jpg"
import { openDialog } from "../../../store/slice/dialogueSlice";
import ScreenDialog from "../../dialogComponent/ScreenDialog";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SendIcon from "@mui/icons-material/Info";




const WithdrawRequest = (props) => {
    const {
        withdraw, total
    } = useSelector((state) => state.withdraw);

    const { dialogue, dialogueData, dialogueType } = useSelector(
        (state) => state.dialogue
    );

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("All");
    const [endDate, setEndDate] = useState("All");
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(10);

    const dispatch = useDispatch();

    useEffect(() => {
        const payload = {
            start: start,
            limit: limit,
            type: "All"
        };
        dispatch(getWithdrawRequest(payload));
    }, [startDate, endDate]);

    useEffect(() => {
        setData(withdraw);
    }, [withdraw]);



    const handleVerify = (row, status) => {

        const payload = {
            userId: row?._id,
            appId: row?.appId,
            hostImageIsVerify: status,
        };
        props.hostImageVerifySwitch(payload);
    }

    const mapData = [
        {
            Header: "Index",
            body: "index",
            Cell: ({ index }) => (
                <span className="  text-nowrap">
                    {(start - 1) * limit + parseInt(index) + 1}
                </span>
            ),
        },
        {
            Header: "Profile Image",
            body: "profileImage",
            Cell: ({ row }) => (
                <div className="logo-image">
                    <img
                        src={row?.userId?.profileImage ? baseURL + "/" + row?.userId?.profileImage : NoImage}
                        alt="logo"
                        style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "7px",
                            objectFit: "cover",
                            border: "1px solid #e8e8e8",
                            boxShadow: "0 0 1px #000000"
                        }}
                    />
                </div>
            ),
        },
        {
            Header: "Host Image",
            body: "profileImage",
            Cell: ({ row }) => (
                <div className="logo-image">
                    <img
                        src={row?.userId?.hostImage ? baseURL + "/" + row?.userId?.hostImage : NoImage}
                        alt="logo"
                        style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "7px",
                            objectFit: "cover",
                            border: "1px solid #e8e8e8",
                            boxShadow: "0 0 1px #000000"
                        }}
                    />
                </div>
            ),
        },


        {
            Header: "Name",
            body: "name",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.userId?.firstName ? row?.userId?.firstName : "-"}
                </span>
            ),
        },

        {
            Header: "Amount",
            body: "amount",
            Cell: ({ row }) => (
                <span className="  text-nowrap">{row?.amount ? row?.amount : "-"}</span>
            ),
        },
        {
            Header: "Withdraw Method",
            body: "withdrawMethod",
            Cell: ({ row }) => (
                <span className="  text-nowrap">{row?.withdrawMethod ? row?.withdrawMethod : "-"}</span>
            ),
        },
        {
            Header: "Date",
            body: "date",

        },
        {
            Header: "WithdrawInfo",
            body: "withdrawInfo",

        },

        {
            Header: "Status",
            body: "status",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.status ? row?.status == 1 ? "Pending" : row?.status == 2 ? "Verified" : row?.status == 3 ? "Rejected" : "-" : "-"}
                </span>
            ),
        },
        {
            Header: "Accept",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.status == 1 ?

                        <Button
                            btnName="Verify"
                            type={"button"}
                            onClick={() => handleVerify(row, 2)}
                            newClass={"male-btn"}
                        /> : "-"
                    }
                </span>
            ),
        },
        {
            Header: "Decline",
            Cell: ({ row }) => (
                <span className="  text-nowrap">
                    {row?.status == 1 ?
                        <Button
                            btnName="Decline"
                            type={"button"}
                            newClass={"cancel-btn"}
                            onClick={() => handleVerify(row, 3)}

                        />
                        : "-"}
                </span>
            ),
        },


    ];



    const handlePageChange = (pageNumber) => {
        setStart(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setStart(1);
        setLimit(value);
    };



    const handleAllDate = () => {
        setStartDate("All");
        setEndDate("All");
    };
    return (
        <div className="appUser">
            <div>
                <Table
                    data={data}
                    type={"server"}
                    mapData={mapData}
                    start={start}
                    limit={limit}
                />
                <Pagination
                    type={"server"}
                    start={start}
                    limit={limit}
                    totalData={total}
                    setStart={setStart}
                    setLimit={setLimit}
                    handleRowsPerPage={handleRowsPerPage}
                    handlePageChange={handlePageChange}
                />
            </div>
        </div>
    );
};
export default connect(null, {
    appUserGet, hostImageVerifySwitch
})(WithdrawRequest);
